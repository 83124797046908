<template>
  <div style="margin: 50% 0;">
    <iframe
      id="iframe"
      class="iframe"
      style="max-width: 1024px;width: 100%;height: 573px;"
      :src="playerIframeUrl"
      frameborder="0"
    ></iframe>
  </div>
</template>
<script>
import StaticContent from '@/services/Api/staticContents';
import { mapGetters } from 'vuex';
export default {
  name: 'BeyondTime',
  mounted() {
    this.getPlayerIframeUrl();
  },
  computed: {
    ...mapGetters('auth', ['getUserDetails']),
  },
  methods: {
    getPlayerIframeUrl() {
      StaticContent.getStaticContent('playerIframeUrl').then(res => {
        if (res.data.Data && res.data.Data.content) {
          let url = res.data.Data.content;
          var d = new Date().getTime();
          setTimeout(() => {
            this.playerIframeUrl = `${url}?customerCode=${this.getUserDetails?.customer_code}&ver=${d}`;
          }, 1000);
        }
      });
    },
  },
  data() {
    return {
      playerIframeUrl: '',
    };
  },
};
</script>
<style scoped lang="scss">
.player-iframe {
  max-width: 1025px;
  height: 573px;
  width: 1025px;
  margin-bottom: 15px;
}
</style>
